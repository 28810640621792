import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

export default function NewsCreatePage () {
  const { mutateAsync, isLoading, isSuccess, error } = useMutation({
    mutationFn: (data) => axios.post(`${process.env.REACT_APP_API_URL}/news`, data, {
      headers: {
        'content-type': 'application/json'
      }
    })
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    mutateAsync(formData)
      .then(() => {
        form.reset()
      })
  }

  return (
    <div className="p-4">
      <div>
        {isSuccess && <p>Новость добавлена</p>}
        {error && <p>Ошибка: {error.message}</p>}
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 max-w-xs"
      >
        <input name="title" placeholder="Заголовок" />
        <textarea name="content" placeholder="Описание" />
        <input type="checkbox" defaultChecked={false} name="push" />
        <button
          disabled={isLoading}
          type="submit"
          className="bg-blue-500 disabled:bg-gray-500"
        >Создать</button>
      </form>
    </div>
  )
}

import { useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import UpsertFormModal from 'components/upsert-form-modal'

function UpsertForm ({ initialData }) {
  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Slug</label>
        <input
          name="slug"
          type="text"
          placeholder="a-z0-9-"
          pattern="[a-z0-9-]{3,20}"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.slug}
          required
        />
      </div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Название</label>
        <input
          name="name"
          type="text"
          placeholder="Название"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.name}
          required
        />
      </div>
    </div>
  )
}

export default function GenreList () {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const queryClient = useQueryClient()

  const { data: genres = [] } = useQuery(['genres'], {
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/genres`).then(res => res.data)
  })

  const handleCreate = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/genres`, data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => queryClient.refetchQueries(['genres']))
      .then(() => setShowCreateModal(false))
  }

  const handleUpdate = (selectedItem) => async (data) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/genres/${selectedItem.id}`, data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => queryClient.refetchQueries(['genres']))
      .then(() => setShowCreateModal(false))
  }

  const deleteItem = (selectedItem) => {
    if (!window.confirm(`Удалить: ${selectedItem.name}?`)) {
      return false
    }

    return axios.delete(`${process.env.REACT_APP_API_URL}/genres/${selectedItem.id}`)
      .then(() => queryClient.refetchQueries(['genres']))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm />}
        isShow={showCreateModal}
        asyncOnSubmit={handleCreate}
        onClose={() => setShowCreateModal(false)}
        title="Добавить жанр"
        buttonText="Добавить"
      />

      <UpsertFormModal
        formChildren={<UpsertForm initialData={selectedItem} />}
        isShow={!!selectedItem}
        asyncOnSubmit={handleUpdate(selectedItem)}
        onClose={() => setSelectedItem(null)}
        title="Обновить жанр"
        buttonText="Обновить"
      />

      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Жанры</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setShowCreateModal(!showCreateModal)}
            >
              Добавить жанр
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Slug
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Название
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {genres.map((item) => (
                      <tr key={item.id}>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.slug}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {item.name}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div
                            className="text-indigo-600 hover:text-indigo-900 inline-block cursor-pointer"
                            onClick={() => setSelectedItem(item)}
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </div>

                          <div
                            className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
                            onClick={() => deleteItem(item)}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

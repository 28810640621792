import { useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond/dist/filepond.min.css'

import cdnvideoService from 'helpers/cdnvideo-service'
import calculateSHA256 from 'helpers/calculate-sha-256'

registerPlugin(FilePondPluginFileValidateType)

async function request (url, opts) {
  const token = await cdnvideoService.getToken()

  return fetch(`${cdnvideoService.BASE_URL}${url}`, {
    ...opts,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(opts?.headers ? opts.headers : {})
    }
  })
}

async function asyncUpload ({ uploadDir, file, options, controller, progress, error, load }) {
  let uploadPartNum = 1
  let startSize = 0

  while (startSize < file.size) {
    const chunk = file.slice(startSize, startSize + options.chunkSize)

    await request(`${uploadDir}/${file.name}?uploadId=${file._uploadId}&uploadPartNum=${uploadPartNum}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: chunk,
      signal: controller.signal
    })
      .then(() => {
        progress(true, startSize, file.size)
      })
      .catch(err => error(err.message))

    startSize += options.chunkSize
    uploadPartNum += 1
  }

  await request(`${uploadDir}/${file.name}?uploadId=${file._uploadId}`, {
    signal: controller.signal,
    method: 'POST',
    body: JSON.stringify({
      sha256: await calculateSHA256(file)
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(json => {
      file._serverResponse = json
      load(json)
    })
    .catch((err) => error(err.message))
}

export default function CdnUpload ({ uploadDir, onSuccess = f => f, ...props }) {
  const [files, setFiles] = useState([])

  return (
    <div className="App">
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={5}
        // acceptedFileTypes={['video/mp4']}
        beforeAddFile={item => {
          return request(`${uploadDir}/${item.file.name}?uploads`, {
            method: 'POST'
          })
            .then(res => res.json())
            .then(json => {
              item.file._uploadId = json.id
              return true
            })
            .catch(() => false)
        }}
        onprocessfile={(err, file) => {
          if (err) {
            console.log('onprocessfile', err, file)
            return
          }
          onSuccess(file)
        }}
        onprocessfileabort={item => {
          return request(`${uploadDir}/${item.file.name}?uploadId=${item.file._uploadId}`, {
            method: 'DELETE'
          })
        }}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            const controller = new AbortController()
            asyncUpload({ uploadDir, file, options, controller, progress, error, load })

            return {
              abort: () => {
                controller.abort()
                abort()
              }
            }
          }
        }}
        allowRevert={false}
        name="file"
        labelIdle='Перетащите файл или <span class="filepond--label-action">выберите</span>'
        labelFileProcessingComplete="Загружено"
        labelTapToCancel="Нажмите для отмены"
        labelFileLoading="Загрузка"
        labelFileProcessing="Загрузка"
        labelFileLoadError="Ошибка во время загрузки"
        labelFileProcessingAborted="Загрузка отменена"
        // chunkSize={1e+6}
        instantUpload={false}
        chunkUploads
        {...props}
      />
    </div>
  )
}

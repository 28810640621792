export default function paidTypeLabel (paidType) {
  switch (paidType) {
    case 'PVOD':
      return 'Аренда'
    case 'AVOD':
      return 'Бесплатно'
    case 'SVOD':
      return 'Подписка'
    case 'EST':
      return 'Покупка навсегда'
    case 'DTR':
      return 'Покупка с лимитом'
    default:
      return paidType
  }
}

import { TrashIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import Pagination from 'components/pagination'
import Table from 'components/table'
import UpsertFormModal from 'components/upsert-form-modal'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Description ({ item }) {
  return (
    <div>
      {item.type === 'movie' && <p>Куплен фильм</p>}
      {item.type === 'season' && <p>Куплен сезон</p>}
      {item.type === 'episode' && <p>Куплен эпизод</p>}

      {item.video && <span>{item.video.title}</span>}
      {item.season && <span> &rarr; {item.season.title} №{item.season.number}</span>}
      {item.episode && <span> &rarr; {item.episode.title} №{item.episode.number}</span>}
    </div>
  )
}

function UpsertForm () {
  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Тип покупки</label>
        <select
          name="type"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        >
          <option value="movie">Фильм</option>
          <option value="episode">Эпизод</option>
          <option value="season">Сезон</option>
        </select>
      </div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">ID</label>
        <input
          name="id"
          type="number"
          placeholder="Название"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Дата окончания</label>
        <input
          name="expiresAt"
          type="datetime-local"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
    </div>
  )
}

function AddPurchase ({ onAdded }) {
  const [isShow, setIsShow] = useState(false)
  const { id } = useParams()

  const handleCreate = async (formData) => {
    request({ method: 'POST', url: `/users/${id}/purchases`, data: formData })
      .then(() => {
        setIsShow(false)
        onAdded()
        toast.success('Покупка добавлена')
      })
      .catch(err => toast.success(err.message))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm />}
        isShow={isShow}
        asyncOnSubmit={handleCreate}
        onClose={() => setIsShow(false)}
        title="Добавить покупку"
        buttonText="Добавить"
      />
      <button onClick={() => setIsShow(!isShow)} className="bg-blue-500 p-2 text-white">Добавить покупку</button>
    </>
  )
}

export default function UserDetailsTabPurchases () {
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['users', String(id), 'purchases', String(currentPage)],
    queryFn: () => request({ url: `/users/${id}/purchases`, params: { page: currentPage } }).then(res => res.data)
  })

  const items = data?.items || []

  const handleDelete = (item) => {
    if (!window.confirm('Удалить покупку?')) {
      return
    }

    request({ method: 'DELETE', url: `/users/purchases/${item.id}` })
      .then(() => {
        refetch()
        toast.success('Покупка удалена')
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <div className="px-4">
        <div className="p-4">
          <AddPurchase onAdded={refetch} />
        </div>
        <Table
          isLoading={isLoading}
          items={items}
          labels={['ID', 'Тип', 'Создан', 'Истекает', 'Описание']}
          renderItem={item => ([item.id, item.type, item.createdAt, item.expiresAt, <Description key={item.id} item={item} />])}
          renderActions={item => (
            <div
              className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
              onClick={() => handleDelete(item)}
            >
              <TrashIcon className="h-5 w-5" />
            </div>
          )}
          pagination={(
            <Pagination
              currentPage={currentPage}
              totalPage={data?.totalPage}
              totalCount={data?.totalCount}
              onChange={setCurrentPage}
            />
          )}
        />
      </div>
    </>
  )
}

import axios from 'axios'
import base64DecodeUnicode from './base64-decode-unicode'

const CONTAINER_ID = '61de86a3ef3db55bc0f64ab0'
const BASE_URL = `https://filespot.platformcraft.ru/2/fs/container/${CONTAINER_ID}/object`

async function getToken () {
  const savedToken = window.localStorage.getItem('cdnvideo-token')
  if (savedToken) {
    try {
      const payload = JSON.parse(base64DecodeUnicode(savedToken.match(/\.(.+?)\./)[1]))
      const nowUnix = Date.now() / 1000
      if (nowUnix >= payload.exp) {
        return null
      }

      return savedToken
    } catch (err) {
      return 'err'
    }
  }

  const adminToken = window.localStorage.getItem('admin-token')
  if (!adminToken) {
    throw new Error('Нужен токен админа')
  }

  const newToken = await axios.request({
    url: `${process.env.REACT_APP_API_URL}/cdnvideo/access-token`,
    headers: {
      Authorization: `Bearer ${adminToken}`
    }
  }).then(res => res.data.token)

  if (newToken) {
    window.localStorage.setItem('cdnvideo-token', newToken)
    return newToken
  }

  throw new Error('Не удалось получить токен')
}

/**
 * @param {string} url
 * @param {import('axios').AxiosRequestConfig} extra
 * @returns {import('axios').AxiosPromise}
 */

export async function request (url, extra) {
  const headers = extra?.headers ?? {}
  headers.Authorization = 'Bearer ' + await getToken()

  return axios({
    baseURL: BASE_URL,
    url,
    ...extra,
    headers
  })
    .catch(err => {
      if (err?.response?.data?.message) {
        err.message = err.response.data.message
      }
      throw err
    })
}

/**
 *
 * @param {string} path
 * @param {FormData} formData
 * @returns
 */
export async function uploadFile (dir, formData) {
  if (!(formData instanceof FormData)) {
    throw new Error('Need FormData')
  }

  const fileName = formData.get('file').name
  const ext = fileName.match(/\.[a-z]+$/i)[0]
  const randomName = `${Math.floor(Math.random() * 1e10)}${ext}`
  const path = `${dir}${randomName}`

  return request(path, {
    method: 'POST',
    data: formData
  }).then(res => res.data)
}

export async function getInfo (path) {
  return request(path, {
    method: 'GET'
  }).then(res => res.data)
}

/**
 *
 * @param {string} path
 */
export async function deleteFile (path) {
  const pathInfo = await getInfo(path)

  if (pathInfo.is_dir === true) {
    throw new Error('This is folder')
  }

  return request(path, {
    method: 'DELETE'
  }).then(res => res.data)
}

async function uploadChunkInit ({ path }) {
  return request(`${path}?uploads`, {
    method: 'POST'
  })
    .then(res => res.data)
}

async function uploadChunkParts ({ path, uploadId, file, onProgress = f => f }) {
  const CHUNK_SIZE = 1024 * 1024 // размер чанка в байтах
  let start = 0
  let uploadPartNum = 1

  while (start < file.size) {
    const chunk = file.slice(start, start + CHUNK_SIZE)
    try {
      await request(path, {
        method: 'PUT',
        params: {
          uploadPartNum,
          uploadId
        },
        data: chunk,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })
    } catch (err) {
      console.error('error upload', err)
      break
    }

    console.log(`Uploaded ${start + chunk.size} bytes`)

    start += CHUNK_SIZE
    uploadPartNum += 1
    onProgress(start)
  }
}

async function uploadChunkFinish ({ path, file, uploadId }) {
  const sha256 = await calculateSHA256(file)

  return request(path, {
    method: 'POST',
    data: {
      sha256
    },
    params: {
      uploadId
    }
  })
    .then(res => res.data)
}

async function uploadChunkCancel ({ path, uploadId }) {
  return request(path, {
    method: 'DELETE',
    params: {
      uploadId
    }
  })
    .then(res => res.data)
}

async function uploadChunkFile ({ path, file, onProgress = f => f, onChangeUploadId = f => f }) {
  const initialData = await uploadChunkInit({ path })
  const uploadId = initialData.id
  onChangeUploadId(uploadId)

  await uploadChunkParts({ path, uploadId, file, onProgress })
  await uploadChunkFinish({ path, uploadId, file })

  onProgress(file.size)
}

async function calculateSHA256 (file) {
  const fileBuffer = await file.arrayBuffer()
  const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const cdnvideoService = {
  request,
  uploadFile,
  uploadChunkFile,
  uploadChunkCancel,
  deleteFile,
  getToken,
  BASE_URL
}

export default cdnvideoService

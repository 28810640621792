import { useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import UpsertFormModal from 'components/upsert-form-modal'
import { request } from 'helpers/api-service'
import { toast } from 'react-toastify'
import Pagination from 'components/pagination'
import SearchInput from 'components/search-input'

function UpsertForm ({ initialData }) {
  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Текст</label>
        <textarea
          rows={5}
          name="text"
          type="text"
          placeholder="Текст"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.text}
          required
        />
      </div>
    </div>
  )
}

function CommentItem ({ comment, onChanged = f => f }) {
  const [selectedComment, setSelectedComment] = useState(null)

  const deleteComment = (comment) => {
    if (!window.confirm(`Удалить: ${comment.name}?`)) {
      return false
    }

    return request({ method: 'DELETE', url: `/comments/${comment.id}` })
      .then(() => toast.success('Удалено'))
      .then(() => onChanged())
      .catch(err => toast.error(err.message))
  }

  const handleUpdate = (comment) => async (data) => {
    request({ method: 'PATCH', url: `/comments/${comment.id}`, data })
      .then(() => toast.success('Обновлен'))
      .then(() => onChanged())
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm initialData={selectedComment} />}
        isShow={!!selectedComment}
        asyncOnSubmit={handleUpdate(selectedComment)}
        onClose={() => setSelectedComment(null)}
        title="Обновить комментарий"
        buttonText="Обновить"
      />

      <tr>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {comment.id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {comment.video.title}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {comment.user.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {comment.text}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <div
            className="text-indigo-600 hover:text-indigo-900 inline-block cursor-pointer"
            onClick={() => setSelectedComment(comment)}
          >
            <PencilSquareIcon className="h-5 w-5" />
          </div>

          <div
            className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
            onClick={() => deleteComment(comment)}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </td>
      </tr>
    </>
  )
}

export default function CommentList () {
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState(undefined)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['comments', currentPage, search],
    queryFn: () => request({ url: '/comments', params: { page: currentPage, q: search } }).then(res => res.data),
    keepPreviousData: true,
    staleTime: 5000
  })

  const comments = data?.items || []

  return (
    <>

      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Актеры</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <AddButton />
          </div> */}
        </div>

        <SearchInput onChange={setSearch} />

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Видео
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Пользователь
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Текст
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {(isLoading || comments.length === 0) && (
                      <tr>
                        <td colSpan={10} className="whitespace-nowrap py-4 px-4 text-sm">
                          {isLoading && 'Загрузка...'}
                          {!isLoading && comments.length === 0 && 'Нет данных'}
                        </td>
                      </tr>
                    )}
                    {comments.map((item, idx) => <CommentItem onChanged={refetch} key={item?.id ?? idx} comment={item} />)}
                  </tbody>
                </table>

                <Pagination
                  currentPage={currentPage}
                  totalPage={data?.totalPage}
                  totalCount={data?.totalCount}
                  onChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import axios from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { useOutletContext, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CdnUpload from 'components/cdn-upload'

function UpdateMainFilesForm ({ video, videoId, onSuccess = f => f }) {
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    axios.patch(`${process.env.REACT_APP_API_URL}/videos/${videoId}`, formData, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => {
        toast.success('Данные обновлены')
        onSuccess()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="block p-2 m-2"
        name="cdnPath"
        placeholder="cdnPath"
        defaultValue={video?.cdnPath}
        required
      />
      <input
        className="block p-2 m-2"
        type="number"
        name="duration"
        placeholder="duration"
        defaultValue={video?.duration}
        required
      />
      <button className="bg-blue-500">Save</button>
    </form>
  )
}

export default function VideoTabFiles () {
  const { id } = useParams()
  const { video } = useOutletContext()
  const queryClient = useQueryClient()

  const refetchVideo = () => {
    queryClient.refetchQueries(['videos', String(id)])
  }

  const uploadDir = `/test/chunk-upload/videos/v-${video.secretKey}`
  return (
    <div>
      {/* {!video.secretKey && <div>У видео нет secretKey</div>}
      {!!video.secretKey && (
        <>
          <div className="mb-4">Будет загружен в папку: {uploadDir}</div>
          <CdnUpload
            acceptedFileTypes={['video/mp4']}
            uploadDir={uploadDir}
            onSuccess={() => window.alert('success')}
          />
        </>
      )} */}

      <div className="mt-4">
        <div>CDN</div>
        {video?.cdnPath && <a className="bg-blue-500" target="_blank" rel="noreferrer" href={`https://my.platformcraft.ru/home${video.cdnPath}`}>Открыть в CDN</a>}
        <UpdateMainFilesForm
          video={video}
          videoId={id}
          onSuccess={refetchVideo}
        />
      </div>
    </div>
  )
}

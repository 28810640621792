import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import CDNVideoUploadImage from 'components/cdnvideo-upload-image'
import { useOutletContext } from 'react-router-dom'

export default function VideoTabImages () {
  const { video } = useOutletContext()
  const { mutateAsync } = useMutation({
    mutationFn: async ({ fieldName, value }) => axios.patch(`${process.env.REACT_APP_API_URL}/videos/${video.id}`, {
      [fieldName]: value
    }),
    retry: 3,
    retryDelay: 3000
  })

  const handleUpdate = (fieldName) => {
    return async (value) => mutateAsync({ fieldName, value })
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-4">
        <section>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 className="text-lg font-medium leading-6 text-gray-900">Логотип</h2>
                <p className="mt-1 text-sm text-gray-500">Рекомендуемый размер 500x250</p>
              </div>

              <div className="mt-6">

                <CDNVideoUploadImage
                  defaultValue={video.logo}
                  accept="image/png"
                  dir={`/images/${video.id}/`}
                  asyncUpdateFn={handleUpdate('logo')}
                />

              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 className="text-lg font-medium leading-6 text-gray-900">Постер</h2>
                <p className="mt-1 text-sm text-gray-500">Рекомендуемый размер 480x720</p>
              </div>

              <div className="mt-6">

                <CDNVideoUploadImage
                  defaultValue={video.poster}
                  dir={`/images/${video.id}/`}
                  asyncUpdateFn={handleUpdate('poster')}
                />

              </div>
            </div>
          </div>
        </section>
      </div>

      <section>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 className="text-lg font-medium leading-6 text-gray-900">Полное изображение</h2>
                <p className="mt-1 text-sm text-gray-500">Рекомендуемый размер 1200x720</p>
              </div>

              <div className="mt-6">
                <CDNVideoUploadImage
                  defaultValue={video.bgImage}
                  dir={`/images/${video.id}/`}
                  asyncUpdateFn={handleUpdate('bgImage')}
                />

              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default function PushNotificationPage () {
  return (
    <>
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Отправить пуш</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
        </div>
        <div className="mt-8 flex flex-col">

          <form className="flex flex-col gap-4 max-w-sm">
            <input name="title" placeholder="Title" />
            <textarea name="description" placeholder="Description" />
            <button type="submit" className="bg-blue-500 disabled:bg-gray-500">Submit</button>
          </form>

        </div>
      </div>
    </>
  )
}

import { useState } from 'react'
import { EyeIcon, PencilSquareIcon, StarIcon } from '@heroicons/react/24/outline'
import CreateVideoModal from 'components/create-video-modal'
import { Link } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import Pagination from 'components/pagination'
import { request } from 'helpers/api-service'
import SearchInput from 'components/search-input'
import paidTypeLabel from 'helpers/paid-type-label'
import clsx from 'clsx'

export default function VideoList () {
  const [showAddModal, setShowAddModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState(undefined)

  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery({
    queryKey: ['videos', currentPage, search],
    queryFn: () => request({ url: '/videos', params: { page: currentPage, q: search } }).then(res => res.data),
    keepPreviousData: true,
    staleTime: 5000
  })

  const movies = data?.items || []

  const handleCreateVideo = async (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/videos`, data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => queryClient.refetchQueries(['movies']))
  }

  return (
    <>
      <CreateVideoModal
        isShow={showAddModal}
        onClose={() => setShowAddModal(false)}
        asyncOnSubmit={handleCreateVideo}
      />

      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Фильмы</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => setShowAddModal(!showAddModal)}
            >
              Добавить фильм
            </button>
          </div>
        </div>

        <SearchInput onChange={setSearch} />

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Название
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Тип
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Просмотры
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Статус
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Доступность
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {(isLoading || movies.length === 0) && (
                      <tr>
                        <td colSpan={10} className="whitespace-nowrap py-4 px-4 text-sm">
                          {isLoading && 'Загрузка...'}
                          {!isLoading && movies.length === 0 && 'Нет данных'}
                        </td>
                      </tr>
                    )}
                    {movies.map((movie) => (
                      <tr key={movie.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0 overflow-hidden rounded-md bg-gray-800">
                              {movie.logo && <img className="h-10 w-10 object-contain" src={movie.logo} alt="" />}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">{movie.title}</div>
                              <div className="text-gray-500 text-xs flex gap-3">
                                <div className="flex items-center">
                                  <EyeIcon className="mr-0.5 flex-shrink-0 h-3 w-3" /> {movie.views}
                                </div>
                                <div className="flex items-center">
                                  <StarIcon className="mr-0.5 flex-shrink-0 h-3 w-3" /> {movie.ratingEtnomedia} / {movie.ratingCount}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 ">
                          <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                            {movie.type === 'movie' && 'Фильм'}
                            {movie.type === 'series' && 'Сериал'}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className="text-xs font-semibold text-gray-800">{movie.views}</span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className={clsx('inline-flex rounded-full px-2 text-xs font-semibold leading-5', {
                            'bg-green-100 text-green-800': movie.status === 'published',
                            'bg-gray-100 text-gray-500': movie.status !== 'published'
                          })}>
                            {movie.status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className={clsx('inline-flex rounded-full px-2 text-xs font-semibold leading-5', {
                            'bg-green-200 text-green-800': movie.paidType === 'SVOD',
                            'bg-blue-100 text-blue-800': movie.paidType === 'PVOD',
                            'bg-yellow-100 text-yellow-800': movie.paidType === 'AVOD'
                          })}>
                            {paidTypeLabel(movie.paidType)}
                          </span>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={`${movie.id}/edit`}
                            state={movie}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <PencilSquareIcon className="h-5 w-5" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <Pagination
                  currentPage={currentPage}
                  totalPage={data?.totalPage}
                  totalCount={data?.totalCount}
                  onChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

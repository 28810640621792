import { useQuery } from '@tanstack/react-query'
import SelectPerson from 'components/select-person'
import { request } from 'helpers/api-service'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function AddPersonForm ({ videoId, onSuccess = f => f }) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(null)
  const [selectedType, setSelectedType] = useState('actor')
  const { data } = useQuery(['persons'], {
    queryFn: () => request({ url: '/persons' }).then(res => res.data),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (selectedPerson?.type) {
      setSelectedType(selectedPerson.type)
    }
  }, [selectedPerson])
  const persons = data?.items || []

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!selectedPerson) {
      return toast.error('Выберите личность')
    }

    const data = {
      personId: selectedPerson.id,
      type: selectedType
    }
    setIsLoading(true)
    request({ method: 'PUT', url: `/videos/${videoId}/persons`, data })
      .then(() => {
        toast.success('Добавлен')
        onSuccess()
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsLoading(false))
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="shadow rounded-md bg-white">
        <div className="py-6 px-4 sm:p-6">
          <div>
            <h2 className="text-lg font-medium leading-6 text-gray-900">Добавить личность</h2>
          </div>

          <div className="mt-6 flex gap-4">
            <SelectPerson
              items={persons}
              onChange={setSelectedPerson}
            />
            <select
              className="mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={selectedType}
              onChange={e => setSelectedType(e.target.value)}
            >
              <option value="actor">Актер</option>
              <option value="director">Режисер</option>
              <option value="producer">Продюсер</option>
              <option value="screenwriter">Сценарист</option>
              <option value="operator">Оператор</option>
              <option value="composer">Композитор</option>
            </select>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 rounded-br-lg rounded-bl-lg">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-300"
            disabled={isLoading}
          >
            Добавить
          </button>
        </div>
      </div>
    </form>
  )
}

function Person ({ person, videoId, type, onUpdated = f => f }) {
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const personTypes = {
    actor: 'Актер',
    director: 'Режисер',
    producer: 'Продюсер',
    screenwriter: 'Сценарист',
    operator: 'Оператор',
    composer: 'Композитор'
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      personId: person.id,
      type: formData.get('type')
    }
    setIsLoading(true)
    request({ method: 'PUT', url: `/videos/${videoId}/persons`, data })
      .then(() => {
        toast.success('Обновлен')
        setIsEdit(false)
        onUpdated()
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsLoading(false))
  }

  const handleDelete = () => {
    if (!window.confirm(`Удалить ${person.name}?`)) {
      return
    }

    setIsLoading(true)
    request({ method: 'DELETE', url: `/videos/${videoId}/persons`, data: { personId: person.id } })
      .then(() => {
        toast.success('Удален')
        onUpdated()
      })
      .catch(err => toast.error(err.message))
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="bg-white shadow rounded-lg p-2">
      <div className="flex flex-col items-center">
        <div className="overflow-hidden rounded-full w-16 h-16">
          <img src={person.image} className="w-full h-full object-cover" alt="" />
        </div>
        <div className="mt-2">{person.name}</div>

        {!isEdit && <div>{personTypes[type]}</div>}

        {isEdit && (
          <form onSubmit={handleSubmit}>
            <select
              className="mt-1 block rounded-md border-gray-300 py-1 pl-2 pr-10 text-sm"
              defaultValue={type}
              name="type"
            >
              {Object.entries(personTypes).map(([k, v]) => <option key={k} value={k}>{v}</option>)}
            </select>
            <button
              type="submit"
              className="bg-blue-500 text-white text-sm p-1 rounded-md disabled:bg-gray-500"
              disabled={isLoading}
            >Сохранить</button>
            <button type="button" className="bg-gray-500 text-white text-sm p-1 rounded-md" onClick={() => setIsEdit(false)}>Отмена</button>
          </form>
        )}
      </div>
      <div className="mt-4 flex justify-between text-sm">
        <button className="bg-orange-500 text-white p-1 rounded-md" onClick={() => setIsEdit(true)}>Изменить</button>
        <button className="bg-red-500 text-white p-1 rounded-md" onClick={handleDelete}>Удалить</button>
      </div>
    </div>
  )
}

export default function VideoTabPersons () {
  const { id } = useParams()
  const { data: items = [], isLoading, refetch } = useQuery(['videos', String(id), 'persons'], {
    queryFn: () => request({ url: `/videos/${id}/persons` }).then(res => res.data.items),
    refetchOnWindowFocus: false
  })

  return (
    <section>
      <AddPersonForm
        videoId={id}
        onSuccess={refetch}
      />
      {isLoading && <div>Loading...</div>}

      {!isLoading && items.length === 0 && <div>Нет актеров</div>}

      {!isLoading && items.length > 0 && (
        <div className="mt-4 grid grid-cols-1 lg:grid-cols-4 gap-4">
          {items.map(item =>
            <Person
              key={item.personId}
              videoId={item.videoId}
              person={item.person}
              type={item.type}
              onUpdated={refetch}
            />
          )}
        </div>
      )}
    </section>
  )
}

import { TrashIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import Pagination from 'components/pagination'
import Table from 'components/table'
import UpsertFormModal from 'components/upsert-form-modal'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function UpsertForm () {
  return (
    <div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Подписка</label>
        <select
          name="subscriptionId"
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        >
          <option value={1}>Месяц</option>
          <option value={2}>Полгода</option>
          <option value={3}>Год</option>
          <option value={0}>Другое</option>
        </select>
      </div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Дата начала</label>
        <input
          name="createdAt"
          type="datetime-local"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
      <div className="mt-3">
        <label className="block text-xs text-gray-700">Дата окончания</label>
        <input
          name="expiresAt"
          type="datetime-local"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          required
        />
      </div>
    </div>
  )
}

function AddSubcription ({ onAdded }) {
  const [isShow, setIsShow] = useState(false)
  const { id } = useParams()

  const handleCreate = async (formData) => {
    request({ method: 'POST', url: `/users/${id}/subscriptions`, data: formData })
      .then(() => {
        setIsShow(false)
        toast.success('Подписка добавлена')
        onAdded()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm />}
        isShow={isShow}
        asyncOnSubmit={handleCreate}
        onClose={() => setIsShow(false)}
        title="Добавить подписку"
        buttonText="Добавить"
      />
      <button onClick={() => setIsShow(!isShow)} className="bg-blue-500 p-2 text-white">Добавить подписку</button>
    </>
  )
}

export default function UserDetailsTabSubscriptions () {
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['users', String(id), 'subscriptions', String(currentPage)],
    queryFn: () => request({ url: `/users/${id}/subscriptions`, params: { page: currentPage } }).then(res => res.data)
  })

  const items = data?.items || []

  const handleDelete = (item) => {
    if (!window.confirm('Удалить подписку?')) {
      return
    }

    request({ method: 'DELETE', url: `/users/subscriptions/${item.id}` })
      .then(() => {
        refetch()
        toast.success('Подписка удалена')
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <div className="px-4">
      <div className="p-4">
        <AddSubcription onAdded={refetch} />
      </div>
      <Table
        isLoading={isLoading}
        items={items}
        labels={['Название', 'Создан', 'Истекает', 'ID платежа']}
        renderItem={item => ([`${item.subscription.name} (ID: ${item.subscriptionId})`, item.createdAt, item.expiresAt, item.paymentId])}
        renderActions={item => (
          <div
            className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
            onClick={() => handleDelete(item)}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        )}
        pagination={(
          <Pagination
            currentPage={currentPage}
            totalPage={data?.totalPage}
            totalCount={data?.totalCount}
            onChange={setCurrentPage}
          />
        )}
      />
    </div>
  )
}

import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Message from './message'

export default function UpsertFormModal ({ isShow = false, onClose = f => f, formChildren, asyncOnSubmit, title, buttonText }) {
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    setIsLoading(true)
    setMessage(null)

    asyncOnSubmit(formData)
      .then(() => {
        form.reset()
        setMessage(null)
      })
      .catch(err => {
        setMessage(err.message)
      })
      .finally(() => setIsLoading(false))
  }

  if (!asyncOnSubmit) {
    throw new Error('Need asyncOnSubmit function')
  }

  if (!formChildren || !React.isValidElement(formChildren)) {
    throw new Error('Need form element')
  }

  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-center text-gray-900">
                  {title ?? 'Создание'}
                </Dialog.Title>
                <form
                  onSubmit={handleSubmit}
                  className="mt-5"
                >
                  {formChildren}
                  {message && <Message className="mt-3">{message}</Message>}

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Загрузка...' : buttonText ?? 'Добавить'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { request } from 'helpers/api-service'
import { Link, Outlet } from 'react-router-dom'

function FilterSide () {
  const { isLoading, data } = useQuery({
    queryKey: ['users'],
    queryFn: () => request({ url: '/users' }).then(res => res.data)
  })

  const items = data?.items || []

  return (
    <aside className="hidden w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Пользователи</h2>
        <p className="mt-1 text-sm text-gray-600">Всего {data?.totalCount} пользователей</p>
        <form className="mt-6 flex space-x-4" action="#">
          <div className="min-w-0 flex-1">
            <div className="relative rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-pink-500 focus:ring-pink-500 sm:text-sm"
                placeholder="Поиск по email, телефон, id"
              />
            </div>
          </div>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
          >
            <FunnelIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span className="sr-only">Search</span>
          </button>
        </form>
      </div>

      <nav className="min-h-0 flex-1 overflow-y-auto border-t border-gray-200 bg-gray-50">
        {isLoading && <div>Загрузка...</div>}

        {!isLoading && items.length > 0 && (
          <ul role="list" className="relative z-0 divide-y divide-gray-200">
            {items.map((user) => (
              <li key={user.id}>
                <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-100">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src="/default-avatar.jpg" alt="" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <Link to={`details/${user.id}`}>
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">{user.name}</p>
                      <p className="truncate text-sm text-gray-500">additional data</p>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </aside>
  )
}

export default function UserLayout () {
  return (
    <>
      <div className="flex h-full">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <Outlet />
            </main>

            <FilterSide />
          </div>
        </div>
      </div>
    </>
  )
}

import { useState } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from 'helpers/api-service'
import { toast } from 'react-toastify'
import Pagination from 'components/pagination'
import { useOutletContext } from 'react-router-dom'

function TagItem ({ tag, onDeleted = f => f }) {
  const handleDelete = (selectedTag) => {
    if (!window.confirm(`Удалить тег: ${selectedTag.tag}?`)) {
      return false
    }

    return request({ method: 'DELETE', url: `/videos/${selectedTag.videoId}/tags`, data: { tag: selectedTag.tag } })
      .then(() => toast.success('Удалено'))
      .then(() => onDeleted())
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <tr>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {tag.tag}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <div
            className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
            onClick={() => handleDelete(tag)}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </td>
      </tr>
    </>
  )
}

function AddTagForm ({ videoId, onSuccess = f => f }) {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: data => request({ method: 'PUT', url: `/videos/${videoId}/tags`, data })
  })

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    formData.set('videoId', videoId)

    mutateAsync(formData)
      .then(() => toast.success('Добавлен'))
      .then(() => onSuccess())
      .then(() => form.reset())
      .catch(err => toast.error(err.message))
  }

  return (
    <form onSubmit={handleSubmit} className="border-1 border-gray-500">
      <input required type="text" name="tag" placeholder="Тег" />
      <button className="bg-blue-500 p-2 text-white" type="submit" disabled={isLoading}>Добавить тег</button>
    </form>
  )
}

export default function VideoTagList () {
  const [currentPage, setCurrentPage] = useState(1)
  const { video } = useOutletContext()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['videos', String(video.id), 'tags', currentPage],
    queryFn: () => request({ url: `/videos/${video.id}/tags`, params: { page: currentPage } }).then(res => res.data),
    keepPreviousData: true,
    staleTime: 5000
  })

  const tags = data?.items || []

  return (
    <section>
      <AddTagForm videoId={video.id} onSuccess={refetch} />

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Тег
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {(isLoading || tags.length === 0) && (
                    <tr>
                      <td colSpan={10} className="whitespace-nowrap py-4 px-4 text-sm">
                        {isLoading && 'Загрузка...'}
                        {!isLoading && tags.length === 0 && 'Нет данных'}
                      </td>
                    </tr>
                  )}
                  {tags.map((item, idx) => <TagItem key={item?.id ?? idx} tag={item} onDeleted={refetch} />)}
                </tbody>
              </table>

              <Pagination
                currentPage={currentPage}
                totalPage={data?.totalPage}
                totalCount={data?.totalCount}
                onChange={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/**
 * @param {import('axios').AxiosRequestConfig} options
 * @returns {import('axios').AxiosPromise}
 */

export async function request (options) {
  const headers = options?.headers || {}

  if (options?.data && (!options?.headers || !options.headers['Content-Type'])) {
    headers['Content-Type'] = 'application/json; charset=utf-8'
  }

  const token = window.localStorage.getItem('admin-token')
  if (token) {
    headers.Authorization = 'Bearer ' + token
  }

  return instance({
    ...options,
    headers
  })
    .catch(err => {
      if (err?.response?.data?.message) {
        err.message = err.response.data.message
      }
      throw err
    })
}

import { Fragment } from 'react'
import clsx from 'clsx'
import { Dialog, Transition } from '@headlessui/react'
import {
  HomeIcon,
  XCircleIcon,
  VideoCameraIcon,
  ChatBubbleLeftIcon,
  CreditCardIcon,
  UsersIcon,
  NewspaperIcon,
  BellIcon,
  AtSymbolIcon,
  CalendarIcon,
  Squares2X2Icon,
  QuestionMarkCircleIcon,
  MegaphoneIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
import AnimateSpin from './animate-spin'

const navigation = [
  { name: 'Панель', to: '/dashboard', icon: HomeIcon, current: true },
  { name: 'Видео', to: '/videos', icon: VideoCameraIcon, current: false },
  { name: 'Сериалы', to: '/series', icon: VideoCameraIcon, current: false },
  { name: 'Новости', to: '/news', icon: NewspaperIcon, current: false },
  { name: 'Пуш', to: '/push', icon: BellIcon, current: false },
  { name: 'Email рассылка', to: '/emailing', icon: AtSymbolIcon, current: false },
  { name: 'Все отзывы', to: '/comments', icon: ChatBubbleLeftIcon, current: false },
  { name: 'Платежи', to: '/payments', icon: CreditCardIcon, current: false },
  { name: 'Коллекции', to: '/collections', icon: Squares2X2Icon, current: false },
  { name: 'Жанры', to: '/genres', icon: Squares2X2Icon, current: false },
  { name: 'Актеры', to: '/persons', icon: UsersIcon, current: false },
  { name: 'Пользователи', to: '/users', icon: UsersIcon, current: false },
  { name: 'Вопрос ответ', to: '/faq', icon: QuestionMarkCircleIcon, current: false },
  { name: 'Реклама', to: '/ads', icon: MegaphoneIcon, current: false },
  { name: 'Календарь', to: '/calendar', icon: CalendarIcon, current: false },
  { name: 'Настройки', to: '/settings', icon: Cog6ToothIcon, current: false }
]

export default function Sidebar ({ className, isOpen = false, onClose = f => f, ...props }) {
  const classes = clsx('min-w-[300px] bg-[#1B1B1F]', className)

  return (
    <div className={classes} {...props}>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="https://etnomedia.kg/assets/images/logo.svg"
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        onClick={onClose}
                        className={({ isActive }) => clsx(
                          {
                            'bg-gray-900 text-white': isActive,
                            'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive
                          },
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        {({ isActive }) => (
                          <>
                            <item.icon
                              className={clsx(
                                {
                                  'text-gray-300': isActive,
                                  'text-gray-400 group-hover:text-gray-300': !isActive
                                },
                                'mr-4 flex-shrink-0 h-6 w-6'
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </>
                        )}
                      </NavLink>
                    )
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
            <img
              className="h-8 w-auto"
              src="https://etnomedia.kg/assets/images/logo.svg"
              alt="Etnomedia"
            />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {navigation.map((item) => {
                return (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) => clsx(
                      {
                        'bg-gray-900 text-white': isActive,
                        'text-gray-300 hover:bg-gray-700 hover:text-white': !isActive
                      },
                      'group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    {({ isActive, isPending }) => (
                      <>
                        <item.icon
                          className={clsx(
                            {
                              'text-gray-300': isActive,
                              'text-gray-400 group-hover:text-gray-300': !isActive
                            },
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        {isPending && <AnimateSpin />}
                      </>
                    )}
                  </NavLink>
                )
              })}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

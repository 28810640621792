import { useQuery } from '@tanstack/react-query'
import Pagination from 'components/pagination'
import Table from 'components/table'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export default function UserDetailsTabPayments () {
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['users', String(id), 'payments', String(currentPage)],
    queryFn: () => request({ url: `/users/${id}/payments`, params: { page: currentPage } }).then(res => res.data)
  })

  const items = data?.items || []

  return (
    <div className="px-4">
      <Table
        isLoading={isLoading}
        items={items}
        labels={['ID', 'Реквизиты', 'Статус', 'Сумма', 'Тип', 'Платформа', 'Провайдер', 'Описание', 'Создан']}
        renderItem={item => ([item.id, item.invoiceId, item.status, `${item.amount} ${item.currency}`, item.type, item.platform, item.provider, item.description, item.createdAt])}
        pagination={(
          <Pagination
            currentPage={currentPage}
            totalPage={data?.totalPage}
            totalCount={data?.totalCount}
            onChange={setCurrentPage}
          />
        )}
      />
    </div>
  )
}

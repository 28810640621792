import { useOutletContext } from 'react-router-dom'

export default function UserDetailsTabProfile () {
  const { user } = useOutletContext()
  const fields = [
    { k: 'Имя', v: user.name },
    { k: 'Статус', v: user.status },
    { k: 'Email', v: user.email ?? '-' },
    { k: 'Телефон', v: user.phone ?? '-' },
    { k: 'Проверен', v: user.isVerified ? 'Да' : 'Нет' },
    { k: 'Дата рождения', v: user.dateOfBirth ?? '-' },
    { k: 'Пол', v: user.sex ?? '-' },
    { k: 'Уровень', v: user.level },
    { k: 'Язык', v: user.lang },
    { k: 'Создан', v: user.createdAt },
    { k: 'Обновлен', v: user.updatedAt ?? '-' },
    { k: 'Последний вход', v: user.lastLoginAt ?? '-' }
  ]
  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {fields.map(({ k, v }) => (
          <div key={k} className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">{k}</dt>
            <dd className="mt-1 text-sm text-gray-900">{v}</dd>
          </div>
        ))}

        {/* <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">About</dt>
          <dd
            className="mt-1 max-w-prose space-y-5 text-sm text-gray-900"
            dangerouslySetInnerHTML={{ __html: profile.about }}
          />
        </div> */}
      </dl>
    </div>
  )
}

let _searchTimerId = 0

export default function SearchInput ({ placeholder = 'Поиск', onChange = f => f }) {
  const handleChangeSearch = e => {
    clearTimeout(_searchTimerId)
    _searchTimerId = setTimeout(() => onChange(e.target.value), 500)
  }

  return (
    <div>
      <input type="text" placeholder={placeholder} onChange={handleChangeSearch} />
    </div>
  )
}

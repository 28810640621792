import { useQuery } from '@tanstack/react-query'
import Pagination from 'components/pagination'
import Table from 'components/table'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

export default function UserDetailsTabViewHistory () {
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['users', String(id), 'view-history', String(currentPage)],
    queryFn: () => request({ url: `/users/${id}/view-history`, params: { page: currentPage } }).then(res => res.data)
  })

  const items = data?.items || []

  return (
    <div className="px-4">
      <Table
        isLoading={isLoading}
        items={items}
        labels={['ID', 'Тип', 'Видео', 'Эпизод', 'Позиция', 'Дата']}
        renderItem={item => ([
          item.id,
          item.type,
          <Link title={`ID ${item.videoId}`} className="text-indigo-600" key="video-overview" to={`/videos/${item.videoId}/overview`}>{item.video?.title}</Link>,
          item.episode ? `[ID:${item.episodeId}] ${item.episode?.title}` : '-', `${item.position} сек`,
          item.updatedAt ?? item.createdAt
        ])}
        pagination={(
          <Pagination
            currentPage={currentPage}
            totalPage={data?.totalPage}
            totalCount={data?.totalCount}
            onChange={setCurrentPage}
          />
        )}
      />
    </div>
  )
}

import { ChatBubbleOvalLeftEllipsisIcon, HandThumbUpIcon, TrashIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { request } from 'helpers/api-service'
import dayjs from 'dayjs'

function Comment ({ data, onClickShowReplies = f => f, onDelete = f => f }) {
  return (
    <article className="p-2 mb-2 text-base border rounded-lg border-gray-300 bg-white shadow-sm">
      <header>
        <div className="text-sm">
          <span title={`ID: ${data.user.id}`}>{data.user.name}</span>
          <time className="text-gray-500 ml-4 text-xs" dateTime={dayjs(data.createdAt).format('DD-MM-YYYY HH:mm:ss')}>{dayjs(data.createdAt).format('HH:mm:ss DD-MM-YY')}</time>
        </div>
      </header>

      <div className="mt-2 text-gray-500">{data.text}</div>

      <footer className="mt-2 flex items-center justify-between">
        <div className="text-sm flex gap-3">
          <span className="flex items-center"><HandThumbUpIcon className="w-4 h-4 mr-1" /> {data.likes}</span>
          {!data.parentId && <button className="flex items-center" onClick={onClickShowReplies}><ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4 mr-1" /> Ответить / Ответы ({data.replies.length})</button>}
        </div>
        <div>
          <button onClick={() => onDelete(data)}><TrashIcon className="w-4 h-4" /></button>
        </div>
      </footer>
    </article>
  )
}

function DisplayComment ({ data: initialData }) {
  const [data, setData] = useState(initialData)
  const [showReplies, setShowReplies] = useState(false)
  const queryClient = useQueryClient()

  const handleDelete = (selectedItem) => {
    if (!confirm('Удалить комментарий?')) {
      return
    }

    request({ method: 'DELETE', url: `/comments/${selectedItem.id}` })
      .then(() => {
        toast.success('Комментарий удален')
        if (selectedItem.parentId) {
          setData({
            ...data,
            replies: data.replies.filter(item => item.id !== selectedItem.id)
          })
          return
        }

        queryClient.refetchQueries(['videos', String(selectedItem.videoId), 'comments'])
      })
      .catch(err => toast.error(err.message))
  }

  const handleSuccess = () => {
    request({ url: `/comments/${data.id}` })
      .then(res => res.data)
      .then(setData)
  }

  return (
    <section className="mb-6">
      <Comment
        data={data}
        onDelete={handleDelete}
        onClickShowReplies={() => setShowReplies(!showReplies)}
      />

      {showReplies && (
        <div className="pl-14">
          <CommentForm
            className="mt-2 mb-2"
            parentId={data.id}
            videoId={data.videoId}
            onSuccess={handleSuccess}
          />

          {data.replies.map(item =>
            <Comment
              key={item.id}
              data={item}
              onDelete={handleDelete}
            />
          )}
        </div>
      )}
    </section>
  )
}

function CommentForm ({ videoId, className, parentId = null, onSuccess = f => f }) {
  const { isLoading, mutateAsync } = useMutation(data => request({ method: 'POST', url: `/videos/${videoId}/comments`, data }))

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    const text = formData.get('text')
    const payload = { text }

    if (parentId) {
      payload.parentId = parentId
    }

    mutateAsync(payload)
      .then(() => {
        form.reset()
        toast.success('Комментарий добавлен')
        onSuccess()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <form
      className={clsx('relative', className)}
      onSubmit={handleSubmit}
    >
      <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        <textarea
          rows={3}
          className="block w-full resize-none border-0 py-3 focus:ring-0"
          name="text"
          placeholder="Комментарий"
          required
        />
        <div className="p-2 bg-gray-50" aria-hidden="true">
          <button
            type="submit"
            className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm text-white shadow-sm hover:bg-indigo-700 disabled:bg-gray-500"
            disabled={isLoading}
          >
            Добавить комментарий
          </button>
        </div>
      </div>
    </form>
  )
}

export default function VideoTabReviews () {
  const { id } = useParams()
  const { data, isLoading, isSuccess, refetch } = useQuery(['videos', String(id), 'comments'], {
    queryFn: () => request({ url: `/videos/${id}/comments` }).then(res => res.data),
    refetchOnWindowFocus: false
  })
  const comments = data?.items || []

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-bold">Отзывы ({comments.length})</h2>
      </div>
      <CommentForm
        videoId={id}
        onSuccess={refetch}
      />
      <div className="mt-6" />
      {isLoading && <div>Loading...</div>}
      {isSuccess && comments.map(comment => <DisplayComment key={comment.id} data={comment} />)}
    </div>
  )
}

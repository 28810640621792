import axios from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { useOutletContext, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CdnUpload from 'components/cdn-upload'

function UpsertTrailerForm ({ video, videoId, onSuccess = f => f }) {
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    axios.put(`${process.env.REACT_APP_API_URL}/videos/${videoId}/trailers`, formData, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(() => {
        toast.success('Данные обновлены')
        onSuccess()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        className="block p-2 m-2"
        name="fileUrl"
        placeholder="fileUrl"
        defaultValue={video?.trailer?.fileUrl}
        required
      />
      <input
        className="block p-2 m-2"
        name="image"
        placeholder="image"
        defaultValue={video?.trailer?.image}
      />
      <input
        className="block p-2 m-2"
        name="duration"
        type="number"
        placeholder="duration"
        defaultValue={video?.trailer?.duration}
      />
      <button className="bg-blue-500" type="submit">Save</button>
    </form>
  )
}

export default function VideoTabTrailers () {
  const { id } = useParams()
  const { video } = useOutletContext()
  const queryClient = useQueryClient()

  const refetchVideo = () => {
    queryClient.refetchQueries(['videos', String(id)])
  }

  return (
    <div>
      <CdnUpload />
      <div>
        <div>Трейлер</div>
        <UpsertTrailerForm
          video={video}
          videoId={id}
          onSuccess={refetchVideo}
        />
      </div>
    </div>
  )
}

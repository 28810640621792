function DefaultPricesSection () {
  return (
    <section>
      <form>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">Цены по умолчанию</h2>
            </div>

            <div className="mt-6">

              Content

            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-300"
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default function SettingsPage () {
  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <DefaultPricesSection />
    </div>
  )
}

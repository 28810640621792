import { TrashIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useState } from 'react'
import AnimateSpin from './animate-spin'
import cdnvideoService from 'helpers/cdnvideo-service'

export default function CDNVideoUploadImage ({
  defaultValue = null,
  asyncUpdateFn = async (f) => f,
  dir,
  title,
  accept = 'image/png, image/jpeg'
}) {
  const updatedDir = `/test${dir}`
  const [message, setMessage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    setMessage(null)
    setIsLoading(true)

    cdnvideoService.uploadFile(updatedDir, formData)
      .then(data => {
        e.target.reset()
        setSelectedFile(null)
        setDownloadUrl(`https://${data.download_url}`)
        return asyncUpdateFn(`https://${data.download_url}`)
      })
      .catch(err => setMessage(err.message))
      .finally(() => setIsLoading(false))
  }

  const handleChange = e => {
    const [file] = e.target.files
    if (file) {
      setSelectedFile(file)
      return
    }
    setSelectedFile(null)
  }

  const handleCancel = (e) => {
    e.target.form.reset()
    setSelectedFile(null)
  }

  const handleDelete = async () => {
    if (!window.confirm('Вы уверены что хотите удалить файл?')) {
      return
    }
    setMessage(null)
    setIsLoading(true)
    const fileName = downloadUrl.match(/\/([^\\/]+)$/)[1]
    cdnvideoService.deleteFile(`${updatedDir}${fileName}`)
      .then(() => asyncUpdateFn(null))
      .then(() => {
        setDownloadUrl(null)
        setSelectedFile(null)
      })
      .catch(err => setMessage(err.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      {title && <div className="text-gray-800 font-bold">{title}</div>}
      <form
        onSubmit={handleSubmit}
        className={clsx({
          'border-red-500': !!selectedFile,
          'border-gray-300': !selectedFile
        }, 'rounded-md relative overflow-hidden')}
      >
        {isLoading && (
          <div className="absolute bg-black bg-opacity-50 inset-0 flex items-center justify-center z-20">
              <AnimateSpin width={40} height={40} />
          </div>
        )}
        <div className="w-full border-2 h-48 bg-chess rounded-md overflow-hidden shadow-sm relative">
          {!selectedFile && !downloadUrl && <div className="h-full flex justify-center items-center text-gray-500 text-lg">Файл не загружен</div>}
          {selectedFile && <img className="h-full w-full object-contain" src={URL.createObjectURL(selectedFile)} alt="preview" />}
          {selectedFile && <div className="absolute bottom-0 bg-red-500 bg-opacity-70 w-full text-center text-white text-xs">Файл не загружен</div>}
          {downloadUrl && !selectedFile && <img className="h-full w-full object-contain" src={`${downloadUrl}?w=200`} alt="preview" />}
          {downloadUrl && !selectedFile && <span onClick={handleDelete} title="Удалить" className="absolute top-0 right-0 z-10 cursor-pointer text-red-500 hover:text-red-300"><TrashIcon className="w-6 h-6" /></span>}
        </div>
        <div className="mt-4 flex justify-between gap-4">
          <label className="flex-1">
            <input
              onChange={handleChange}
              type="file"
              name="file"
              accept={accept}
              className="block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
              cursor-pointer
            "/>
          </label>
          <div>
            <button
              type="button"
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
              disabled={!selectedFile}
              onClick={handleCancel}
            >
              Отмена
            </button>

            <button
              type="submit"
              disabled={!selectedFile}
              className="ml-2 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            >
              Загрузить
            </button>
          </div>
        </div>
      </form>
      {message && <div className="mt-2 bg-red-300 text-white p-2 rounded-sm">Ошибка: {message}</div>}
    </div>
  )
}

import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOutletContext, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const paidTypes = [
  { key: 'PVOD', name: 'Аренда', description: 'Срок 7 дней' },
  { key: 'SVOD', name: 'Подписка', description: '' },
  { key: 'AVOD', name: 'Бесплатно', description: '' }
  // { key: 'EST', name: 'Покупка навсегда', description: 'Пока недоступен' }
]

function SectionPaidType () {
  const { video } = useOutletContext()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState(paidTypes.find(v => v.key === video.paidType))
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (value) => axios.patch(`${process.env.REACT_APP_API_URL}/videos/${id}`, { paidType: value })
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (!selected?.key) {
      return toast.error('Выберите тип оплаты')
    }

    mutateAsync(selected.key)
      .then(() => {
        toast.success(`Тип оплаты обновлен на: ${selected.name}`)
        queryClient.refetchQueries(['videos', id])
      })
  }

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 className="text-lg font-medium leading-6 text-gray-900">Тип оплаты</h2>
              <p className="mt-1 text-sm text-gray-500">Установлен: {paidTypes.find(v => v.key === video.paidType)?.name}</p>
            </div>

            <div className="mt-6">

              <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="sr-only"> Privacy setting </RadioGroup.Label>
                <div className="-space-y-px rounded-md bg-white">
                  {paidTypes.map((item, settingIdx) => (
                    <RadioGroup.Option
                      key={item.name}
                      value={item}
                      className={({ checked }) =>
                        classNames(
                          settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          settingIdx === item.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                          'relative border p-4 flex cursor-pointer focus:outline-none'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                              'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                            >
                              {item.name}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                            >
                              {item.description}
                            </RadioGroup.Description>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>

            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-300"
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

function SectionBasicSettings () {
  const { video } = useOutletContext()
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { register, handleSubmit } = useForm()
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: data => axios.patch(`${process.env.REACT_APP_API_URL}/videos/${id}`, data)
  })

  const onSubmit = data => {
    mutateAsync(data)
      .then(() => {
        queryClient.refetchQueries(['videos', id])
        toast.success('Данные обновлены')
      })
  }

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                Общие настройки
              </h2>
              {/* <p className="mt-1 text-sm text-gray-500">...</p> */}
            </div>

            <div className="mt-6 grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Статус</label>
                <select
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.status}
                  {...register('status', { required: true })}
                >
                  <option value="published">Опубликован</option>
                  <option value="disabled">Отключен</option>
                  <option value="expired">Истек срок действия</option>
                </select>
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Возраст</label>
                <select
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.ageRestrictions}
                  {...register('ageRestrictions', { required: true })}
                >
                  <option value={0}>0+</option>
                  <option value={6}>6+</option>
                  <option value={12}>12+</option>
                  <option value={16}>16+</option>
                  <option value={18}>18+</option>
                </select>
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Название</label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.title}
                  {...register('title', { required: true })}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Слоган</label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.quote}
                  {...register('quote')}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Описание</label>
                <textarea
                  rows={3}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.description}
                  {...register('description')}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Короткое описание</label>
                <textarea
                  rows={3}
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.shortDescription}
                  {...register('shortDescription')}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Год</label>
                <input
                  type="number"
                  className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
                  defaultValue={video.year}
                  {...register('year', { required: true })}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Срок окончания</label>
                <input
                  type="date"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  defaultValue={video.expiresAt ? dayjs(video.expiresAt).format('YYYY-MM-DD') : null}
                  {...register('expiresAt', { required: true })}
                />
              </div>

              <div className="col-span-4 sm:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Продолжительность</label>
                <input
                  type="number"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  defaultValue={video?.duration}
                  {...register('duration', { required: false })}
                />
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-300"
            >
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default function VideoTabEdit () {
  return (
    <div className="space-y-6">
      <SectionBasicSettings />
      {/* <SectionPaidType /> */}
    </div>
  )
}

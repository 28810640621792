import {
  CalendarIcon,
  CurrencyDollarIcon,
  LinkIcon,
  EyeIcon
} from '@heroicons/react/20/solid'
import axios from 'axios'
import clsx from 'clsx'
import { useQuery } from '@tanstack/react-query'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { ChartPieIcon, ChatBubbleBottomCenterIcon, CogIcon, DocumentDuplicateIcon, DocumentIcon, EllipsisHorizontalCircleIcon, PhotoIcon, PlayIcon, Squares2X2Icon, StarIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import paidTypeLabel from 'helpers/paid-type-label'

function Heading ({ video }) {
  return (
    <div className="p-4 sm:p-6 lg:p-8 lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {video.title}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <span className="inline-flex items-center rounded bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
              {video.status}
            </span>
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <EyeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {video.views}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <StarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {video.ratingEtnomedia} / {video.ratingCount}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {paidTypeLabel[video.paidType]}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Активен до 15.09.2024
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0">
        <span>
          <a
            href={`${process.env.REACT_APP_WEBSITE_URL}/watch/${video.id}`}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
            Открыть на сайте
          </a>
        </span>
      </div>
    </div>
  )
}

const tabs = [
  { name: 'Основное', to: 'edit', icon: CogIcon },
  { name: 'Изображения', to: 'images', icon: PhotoIcon },
  { name: 'Файлы', to: 'files', icon: DocumentIcon },
  { name: 'Трейлеры', to: 'trailers', icon: PlayIcon },
  { name: 'Сезоны', to: 'seasons', icon: DocumentDuplicateIcon },
  { name: 'Цены', to: 'prices', icon: CurrencyDollarIcon },
  { name: 'Коллекции', to: 'collections', icon: Squares2X2Icon },
  { name: 'Жанры', to: 'genres', icon: Squares2X2Icon },
  { name: 'Теги', to: 'tags', icon: Squares2X2Icon },
  { name: 'Отзывы', to: 'reviews', count: '4', icon: ChatBubbleBottomCenterIcon },
  { name: 'Актеры', to: 'persons', count: '0', icon: UserGroupIcon },
  { name: 'Аналитика', to: 'analytics', icon: ChartPieIcon },
  // { name: 'Прочее', to: 'others', icon: EllipsisHorizontalCircleIcon },
  { name: 'Обзор', to: 'overview', icon: EyeIcon }
]

function Navigation () {
  return (
    <aside className="lg:col-span-3 mb-6">
      <nav className="space-y-1 shadow bg-white sm:overflow-hidden sm:rounded-md">
        {tabs.map((item) => (
          <NavLink
            key={item.name}
            to={item.to}
            className={({ isActive }) => clsx({
              'bg-blue-500 text-white hover:bg-blue-400': isActive,
              'text-gray-900 hover:text-gray-900 hover:bg-blue-50': !isActive
            }, 'group px-3 py-2 flex items-center text-sm font-medium')}
          >
            {({ isActive }) => (
              <>
                <item.icon
                  className={clsx({
                    'text-white': isActive,
                    'text-gray-400 group-hover:text-gray-500': !isActive
                  }, 'flex-shrink-0 -ml-1 mr-3 h-6 w-6')}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </>
            )}
          </NavLink>
        ))}
      </nav>
    </aside>
  )
}

export default function VideoView () {
  const { id } = useParams()
  const { data, isLoading } = useQuery(['videos', String(id)], {
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/videos/${id}`).then(res => res.data),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isLoading && !data) {
    return <div>404</div>
  }

  return (
    <>
      <Heading video={data} />
      {/* <Tabs /> */}

      {/* <div className="p-4 sm:p-6 lg:p-8 min-h-screen"> */}
      <div className="px-4 pb-4 sm:px-6 sm:pb-6 lg:px-8 lg:pb-8 min-h-screen lg:grid lg:grid-cols-12 lg:gap-x-5">
        <Navigation />
        <div className="lg:col-span-9 lg:px-0">
          <Outlet context={{ video: data }} />
        </div>
      </div>
    </>
  )
}

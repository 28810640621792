import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

export default function Message ({ children, onClose, className, ...props }) {
  return (
    <div className={clsx('rounded-md bg-blue-50 p-4  border border-gray-300', className)} {...props}>
      <div className="flex-1 md:flex md:justify-between">
        <p className="text-sm text-blue-700">{children}</p>
        <p className="mt-3 text-sm md:mt-0 md:ml-6">
          <div
            onClick={onClose}
            className="cursor-pointer text-gray-400 inline-block"
          >
            <XMarkIcon className="h-4 w-4" />
          </div>
        </p>
      </div>
    </div>
  )
}

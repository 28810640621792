import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function FormUpdateEpisode ({ episode }) {
  const [isShow, setIsShow] = useState(false)
  const { mutateAsync, isLoading } = useMutation(data => request({ method: 'PATCH', url: `/episodes/${episode.id}`, data }))

  const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    mutateAsync(formData)
      .then(() => toast.success('Сезон обновлен'))
      .catch(err => toast.error(err.message))
  }

  return (
    <div className="border border-blue-500 p-2 mb-10">
      <div className="border border-blue-500 p-2 mb-2 cursor-pointer" onClick={() => setIsShow(!isShow)}>{episode.title} [{episode.number}]</div>

      {isShow && (
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <input type="number" name="number" placeholder="number" defaultValue={episode.number} />
          <input type="text" name="title" placeholder="title" defaultValue={episode.title} />
          <textarea name="description" placeholder="description" defaultValue={episode.description} />
          <input type="text" name="image" placeholder="image" defaultValue={episode.image} />
          <input type="number" name="duration" placeholder="duration" defaultValue={episode.duration} />
          <button type="submit" className="bg-blue-100 disabled:bg-gray-500" disabled={isLoading}>Сохранить</button>
          <button type="button" className="bg-red-100" onClick={() => setIsShow(false)}>Отмена</button>
        </form>
      )}
    </div>
  )
}

function FormAddEpisode ({ seasonId, onAdded = f => f }) {
  const { mutateAsync, isLoading } = useMutation(data => request({ method: 'POST', url: '/episodes', data }))

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    formData.set('seasonId', seasonId)

    mutateAsync(formData)
      .then(() => {
        toast.success('Эпизод добавлен')
        form.reset()
        onAdded()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <div>
      <form className="flex flex-col gap-4 mb-20" onSubmit={handleSubmit}>
      <input type="number" name="number" placeholder="number" />
        <input type="text" name="title" placeholder="title" />
        <textarea name="description" placeholder="description" />
        <input type="text" name="image" placeholder="image" />
        <input type="number" name="duration" placeholder="duration" />
        <button type="submit" className="bg-blue-100 disabled:bg-gray-500" disabled={isLoading}>Добавить</button>
      </form>
    </div>
  )
}

function DisplayEpisodes ({ items }) {
  return items.map((item, idx) => {
    return (
      <FormUpdateEpisode key={item.id || idx} episode={item} />
    )
  })
}

export default function VideoTabEpisodes () {
  const { seasonId } = useParams()

  const { data, isLoading, refetch } = useQuery(['seasons', String(seasonId)], {
    queryFn: () => request({ url: `/seasons/${seasonId}` }).then(res => res.data)
  })
  const episodes = data?.episodes || []

  return (
    <div>
      <FormAddEpisode
        seasonId={seasonId}
        onAdded={refetch}
      />
      {isLoading && <div>Loading...</div>}
      {!isLoading && episodes.length === 0 && <div>Нет эпизодов</div>}
      {!isLoading && episodes.length > 0 && <DisplayEpisodes items={episodes} />}
    </div>
  )
}

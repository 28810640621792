import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

let _timerId = 0

export default function Pagination ({ currentPage = 1, totalCount = 0, totalPage = 0, onChange = f => f }) {
  const [page, setPage] = useState(currentPage)

  const handleChangeManual = (e) => {
    setPage(parseInt(e.target.value))

    if (_timerId) {
      clearTimeout(_timerId)
    }

    _timerId = setTimeout(() => onChange(parseInt(e.target.value)), 500)
  }

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
      aria-label="Pagination"
    >
      <div className="flex flex-1 justify-between sm:justify-start">
        <input
          type="number"
          min={1}
          max={totalPage}
          className="inline-flex w-20 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder=""
          onChange={handleChangeManual}
          value={page}
        />
        <button
          type="button"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-60"
          onClick={() => onChange(currentPage - 1)}
          disabled={currentPage <= 1}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        <button
          type="button"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-60"
          onClick={() => onChange(currentPage + 1)}
          disabled={currentPage >= totalPage}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Страница <span className="font-medium">{currentPage}</span> из <span className="font-medium">{totalPage}</span> (всего записей <span className="font-medium">{totalCount}</span>)
        </p>
      </div>
    </nav>
  )
}

import base64DecodeUnicode from 'helpers/base64-decode-unicode'
import { useState } from 'react'
import { Navigate, Outlet, useNavigation } from 'react-router-dom'
import Header from '../components/header'
import Sidebar from '../components/sidebar'
import Spinner from '../components/spinner'

export default function Layout () {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const { state } = useNavigation()

  try {
    const token = window.localStorage.getItem('admin-token')
    const payload = JSON.parse(base64DecodeUnicode(token.match(/\.(.+?)\./)[1]))
    if (payload.level < 10) {
      throw new Error()
    }
  } catch {
    return <Navigate to="/login" />
  }

  return (
    <>
      <div>
        <Sidebar isOpen={sidebarIsOpen} onClose={() => setSidebarIsOpen(false)} />
        <div className="md:ml-64 flex flex-col relative min-h-screen">
          <Header onClickSidebar={() => setSidebarIsOpen(!sidebarIsOpen)} />

          <main className="absolute inset-0 top-16 overflow-auto">
            {state === 'loading' ? <Spinner className="!h-full" /> : <Outlet />}
          </main>
        </div>
      </div>
    </>
  )
}

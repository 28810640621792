import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useOutletContext, useParams } from 'react-router-dom'

const paidTypes = [
  { key: 'PVOD', title: 'Аренда', description: 'Срок 7 дней', users: '621 users' },
  { key: 'SVOD', title: 'Подписка', description: 'Доступен по подписке', users: '1200 users' },
  { key: 'AVOD', title: 'Бесплатно', description: 'Просмотр рекламы', users: '2740 users' }
  // { key: 'EST', title: 'Покупка навсегда', description: 'Пока недоступен', users: '621 users' }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function VideoTabPrices () {
  const { video } = useOutletContext()
  const queryClient = useQueryClient()
  const [paidType, setPaidType] = useState(paidTypes.find(v => v.key === video.paidType))
  const { id } = useParams()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (value) => axios.patch(`${process.env.REACT_APP_API_URL}/videos/${id}`, { paidType: value })
  })

  const handleSubmit = e => {
    e.preventDefault()
    if (!paidType?.key) {
      return toast.error('Выберите тип оплаты')
    }

    mutateAsync(paidType.key)
      .then(() => {
        queryClient.refetchQueries(['videos', id])
        toast.success(`Тип оплаты обновлен на: ${paidType.title}`)
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>Усановлен: {paidTypes.find(v => v.key === video.paidType)?.title}</div>
      <RadioGroup value={paidType} onChange={setPaidType}>
        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {paidTypes.map((item) => (
            <RadioGroup.Option
              key={item.key}
              value={item}
              className={({ checked, active }) =>
                classNames(
                  checked ? 'border-transparent' : 'border-gray-300',
                  active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                  'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="block font-medium text-gray-900">
                        {item.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                        {item.description}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="mt-5 flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-300"
        >
          Сохранить
        </button>
      </div>
    </form>
  )
}

import { useState } from 'react'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import UpsertFormModal from 'components/upsert-form-modal'
import { request } from 'helpers/api-service'
import { toast } from 'react-toastify'
import Pagination from 'components/pagination'
import SearchInput from 'components/search-input'

function UpsertForm ({ initialData }) {
  return (
    <div>
      <div>
        <label className="block text-xs text-gray-700">Тип</label>
        <select
          name="type"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.type}
        >
          <option value="actor">Актер</option>
          <option value="director">Режиссер</option>
          <option value="producer">Продюсер</option>
          <option value="screenwriter">Сценарист</option>
          <option value="operator">Оператор</option>
          <option value="composer">Композитор</option>
          <option value="author">Автор</option>
        </select>
      </div>

      <div className="mt-3">
        <label className="block text-xs text-gray-700">Имя</label>
        <input
          name="name"
          type="text"
          placeholder="Имя"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.name}
          required
        />
      </div>

      <div className="mt-3">
        <label className="block text-xs text-gray-700">Изображение</label>
        <input
          name="image"
          type="text"
          placeholder="Изображение"
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={initialData?.image}
          required
        />
      </div>
    </div>
  )
}

function AddButton ({ onAdded = f => f }) {
  const [showModal, setShowModal] = useState(false)

  const handleCreate = async (data) => {
    return request({ method: 'POST', url: '/persons', data })
      .then(() => onAdded())
      .then(() => setShowModal(false))
      .then(() => toast.success('Создан'))
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm />}
        isShow={showModal}
        asyncOnSubmit={handleCreate}
        onClose={() => setShowModal(false)}
        title="Добавить актера"
        buttonText="Добавить"
      />
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
        onClick={() => setShowModal(!showModal)}
      >
        Добавить актера
      </button>
    </>
  )
}

function PersonItem ({ person, onChanged = f => f }) {
  const [selectedPerson, setSelectedPerson] = useState(null)

  const deletePerson = (person) => {
    if (!window.confirm(`Удалить: ${person.name}?`)) {
      return false
    }

    return request({ method: 'DELETE', url: `/persons/${person.id}` })
      .then(() => toast.success('Удалено'))
      .then(() => onChanged())
      .catch(err => toast.error(err.message))
  }

  const handleUpdate = (person) => async (data) => {
    request({ method: 'PATCH', url: `/persons/${person.id}`, data })
      .then(() => toast.success('Обновлен'))
      .then(() => onChanged())
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <UpsertFormModal
        formChildren={<UpsertForm initialData={selectedPerson} />}
        isShow={!!selectedPerson}
        asyncOnSubmit={handleUpdate(selectedPerson)}
        onClose={() => setSelectedPerson(null)}
        title="Обновить актера"
        buttonText="Обновить"
      />

      <tr>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {person.id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {person.name}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {person.type}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div className="h-10 w-10 flex-shrink-0 overflow-hidden rounded-md bg-gray-100 text-center">
            {person.image && <img className="h-10 w-10 object-contain" src={person.image} alt="" />}
          </div>
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <div
            className="text-indigo-600 hover:text-indigo-900 inline-block cursor-pointer"
            onClick={() => setSelectedPerson(person)}
          >
            <PencilSquareIcon className="h-5 w-5" />
          </div>

          <div
            className="ml-4 text-red-600 hover:text-red-900 inline-block cursor-pointer"
            onClick={() => deletePerson(person)}
          >
            <TrashIcon className="h-5 w-5" />
          </div>
        </td>
      </tr>
    </>
  )
}

export default function PersonList () {
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState(undefined)

  const { data, isLoading } = useQuery({
    queryKey: ['persons', currentPage, search],
    queryFn: () => request({ url: '/persons', params: { page: currentPage, q: search } }).then(res => res.data),
    keepPreviousData: true,
    staleTime: 5000
  })

  const persons = data?.items || []

  return (
    <>

      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Актеры</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <AddButton />
          </div>
        </div>

        <SearchInput onChange={setSearch} />

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Имя
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Тип
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Изображение
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {(isLoading || persons.length === 0) && (
                      <tr>
                        <td colSpan={10} className="whitespace-nowrap py-4 px-4 text-sm">
                          {isLoading && 'Загрузка...'}
                          {!isLoading && persons.length === 0 && 'Нет данных'}
                        </td>
                      </tr>
                    )}
                    {persons.map((item, idx) => <PersonItem key={item?.id ?? idx} person={item} />)}
                  </tbody>
                </table>

                <Pagination
                  currentPage={currentPage}
                  totalPage={data?.totalPage}
                  totalCount={data?.totalCount}
                  onChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { PhoneIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { request } from 'helpers/api-service'
import { NavLink, Outlet, useParams } from 'react-router-dom'

const tabs = [
  { name: 'Профиль', to: '' },
  { name: 'Платежи', to: 'payments' },
  { name: 'Покупки', to: 'purchases' },
  { name: 'Подписки', to: 'subscriptions' },
  { name: 'Устройства', to: 'devices' },
  { name: 'История просмотров', to: 'view-history' },
  { name: 'Акаунты', to: 'accounts' }
]

export default function UserDetails () {
  const { id } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['users', String(id)],
    queryFn: () => request({ url: `/users/${id}` }).then(res => res.data)
  })

  if (isLoading) {
    return (
      <div>Загрузка...</div>
    )
  }

  return (
    <article>
      <div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <img
                className="h-12 w-12 rounded-full ring-2 ring-white"
                src="/default-avatar.jpg"
                alt=""
              />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                <h1 className="truncate text-2xl font-bold text-gray-900">{data.name}</h1>
              </div>
              <div className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                >
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span>Позвонить</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">{data.name}</h1>
          </div>
        </div>
      </div>

      <div className="mt-6 sm:mt-2 2xl:mt-5">
        <div className="border-b border-gray-200">
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab, idx) => (
                <NavLink
                  key={idx}
                  to={tab.to}
                  className={({ isActive }) => clsx('whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm', {
                    'border-pink-500 text-gray-900': isActive,
                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': !isActive
                  })}
                  end
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <Outlet context={{ user: data }} />
    </article>
  )
}

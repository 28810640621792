import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom'

export default function NewsEditPage () {
  const { id } = useParams()
  const { mutateAsync, isLoading: isUpdating, isSuccess: isUpdated, error: updateError } = useMutation({
    mutationFn: data => axios.patch(`${process.env.REACT_APP_API_URL}/news/${id}`, data, {
      headers: {
        'content-type': 'application/json'
      }
    })
  })

  const { data, isLoading, error } = useQuery(['news-edit', id], {
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/news/${id}`).then(res => res.data)
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    mutateAsync(formData)
  }

  if (isLoading) {
    return <div>Loading....</div>
  }

  return (
    <div className="p-4">
      <div>
        {isUpdated && <div>Новость обновлена</div>}
        {updateError && <div>Ошибка при обновлении: {updateError.message}</div>}
        {error && <div>Ошибка: {error.message}</div>}
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 max-w-sm"
      >
        <input
          name="title"
          defaultValue={data.title}
          placeholder="Title"
        />
        <textarea
          name="content"
          placeholder="Content"
          defaultValue={data.content}
        />
        <input
          type="checkbox"
          disabled
          defaultChecked={data.push}
        />
        <button
          type="submit"
          disabled={isUpdating}
          className="bg-blue-500 disabled:bg-gray-500"
        >Update</button>
      </form>
    </div>
  )
}

import { useOutletContext } from 'react-router-dom'

export default function VideoTabOverview () {
  const { video } = useOutletContext()

  if (!video) {
    return
  }

  return (
    <div>
      {Object.entries(video).map(([key, value]) => <p key={key}><strong>{key}:</strong> {JSON.stringify(value)}</p>)}
    </div>
  )
}

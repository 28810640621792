import { useState } from 'react'
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import { request } from 'helpers/api-service'
import Pagination from 'components/pagination'
import SearchInput from 'components/search-input'
import { UserIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import SideOver from 'components/sideover'
import clsx from 'clsx'

function PaymentDetails ({ payment }) {
  const { data, isLoading } = useQuery({
    queryKey: ['payments', String(payment.id)],
    queryFn: () => request({ url: `/payments/${payment.id}` }).then(res => res.data)
  })

  if (isLoading) {
    return <div>Загрузка...</div>
  }

  return (
    <div className="absolute inset-0 px-4 sm:px-6">
      <p>ID: {data.id}</p>
      <p>Статус: {data.status}</p>
      <p>Сумма: {data.amount}</p>
      <p>Валюта: {data.currency}</p>
      <p>Платформа: {data.platform}</p>
      <p>Провайдер: {data.provider}</p>
      <p>Система оплаты: {data.paymentMethod}</p>
      <p>Заметки: {data.note}</p>
      <p>Описание: {data.description}</p>
      <p>Другие данные: {data.additionalData}</p>
      <p>Создан: {data.createdAt}</p>
      <p>Обновлен: {data.updatedAt}</p>

      <div className="mt-4">
        <p className="font-bold">Пользователь</p>
        <p>ID: {data.userId}</p>
        <p>name: {data.user.name}</p>
        <p><Link to={`/users/details/${data.userId}`} className="text-indigo-600">Перейти на страницу пользователя</Link></p>
      </div>

      <div className="mt-4">
        <p className="font-bold">Тип покупки: {data.type}</p>
        {data.type === 'subscription' && (
          <>
            <p>ID: {data.subscription.id}</p>
            <p>Название: {data.subscription.name}</p>
          </>
        )}
        {data.type === 'buy_movie' && (
          <>
            <p>ID: {data.video.id}</p>
            <p>Название: {data.video.title}</p>
          </>
        )}
        {data.type === 'buy_season' && (
          <>
            <p>ID: {data.season.id}</p>
            <p>Название: {data.season.title}</p>
            <p>Номер: {data.season.number}</p>
          </>
        )}
        {data.type === 'buy_episode' && (
          <>
            <p>ID: {data.episode.id}</p>
            <p>Название: {data.episode.title}</p>
            <p>Номер: {data.episode.number}</p>
            <p>ID сезона: {data.episode.seasonId}</p>
          </>
        )}
      </div>
    </div>
  )
}

function PaymentItem ({ payment }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <SideOver
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Платеж №${payment.id}`}
      >
        <PaymentDetails payment={payment} />
      </SideOver>
      <tr>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.id}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          <Link title="Подробнее о пользователе" to={`/users/details/${payment.userId}`} className="inline-flex items-center text-indigo-600">
            <UserIcon width={16} height={16} /> <span className="ml-1">{payment.user.name} [{payment.userId}]</span>
          </Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.invoiceId}
        </td>
        <td className={clsx('whitespace-nowrap px-3 py-4 text-sm', {
          'text-green-500': payment.status === 'accepted'
        })}>
          {payment.status}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.amount} {payment.currency}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.type}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.platform}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.provider}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.description}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          {payment.createdAt}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <div
            className="text-indigo-600 hover:text-indigo-900 inline-block cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <DocumentMagnifyingGlassIcon className="h-5 w-5" />
          </div>
        </td>
      </tr>
    </>
  )
}

export default function PaymentList () {
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState(undefined)

  const { data, isLoading } = useQuery({
    queryKey: ['payments', currentPage, search],
    queryFn: () => request({ url: '/payments', params: { page: currentPage, q: search } }).then(res => res.data),
    keepPreviousData: true,
    staleTime: 5000
  })

  const items = data?.items || []

  return (
    <>

      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Платежи</h1>
            {/* <p className="mt-2 text-sm text-gray-700">Текст</p> */}
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <AddButton />
          </div> */}
        </div>

        <SearchInput onChange={setSearch} />

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        ID
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Пользователь
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Реквизит
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Статус
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Стоимость
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Оплата
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Платформа
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Провайдер
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Описание
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Дата создания
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-gray-500">
                    {(isLoading || items.length === 0) && (
                      <tr>
                        <td colSpan={99} className="whitespace-nowrap py-4 px-4 text-sm">
                          {isLoading && 'Загрузка...'}
                          {!isLoading && items.length === 0 && 'Нет данных'}
                        </td>
                      </tr>
                    )}
                    {items.map((item, idx) => <PaymentItem key={item?.id ?? idx} payment={item} />)}
                  </tbody>
                </table>

                <Pagination
                  currentPage={currentPage}
                  totalPage={data?.totalPage}
                  totalCount={data?.totalCount}
                  onChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function Table ({ isLoading, items = [], labels = [], renderActions = null, renderItem = f => f, pagination = null }) {
  return (
    <div className="mt-4 flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full pt-2 pb-4 align-middle px-2">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {labels.map((label, idx) => <th key={idx} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{label}</th>)}
                  {renderActions && (
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {(isLoading || items.length === 0) && (
                  <tr>
                    <td colSpan={10} className="whitespace-nowrap py-4 px-4 text-sm">
                      {isLoading && 'Загрузка...'}
                      {!isLoading && items.length === 0 && 'Нет данных'}
                    </td>
                  </tr>
                )}
                {items.map((item, idx) => (
                  <tr key={item?.id || idx}>
                    {renderItem(item).map((value, idx) => <td key={idx} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{value}</td>)}
                    {renderActions && (
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {renderActions(item)}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {pagination}
          </div>
        </div>
      </div>
    </div>
  )
}

import DashboardPage from 'pages/dashboard/index'
import NoMatch from 'pages/no-match'
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

import VideosList from 'routes/videos/video-list'
import { ToastContainer } from 'react-toastify'
import Layout from 'routes/layout'
import VideoView from 'routes/videos/video-view'
import VideoTabEdit from 'routes/videos/video-tab-edit'
import VideoTabOverview from 'routes/videos/video-tab-overview'
import VideoTabReviews from 'routes/videos/video-tab-reviews'
import VideoTabPersons from 'routes/videos/video-tab-persons'
import VideoTabAnalytics from 'routes/videos/video-tab-analytics'
import VideoTabFiles from 'routes/videos/video-tab-files'
import VideoTabPrices from 'routes/videos/video-tab-prices'
import VideoTabImages from 'routes/videos/video-tab-images'
import VideoTabGenres from 'routes/videos/video-tab-genres'
import VideoTabCollections from 'routes/videos/video-tab-collections'
import VideoTabOthers from 'routes/videos/video-tab-others'
import PersonList from 'routes/persons/person-list'
import NewsEditPage from 'routes/news/edit'
import NewsCreatePage from 'routes/news/create'
import PushNotificationPage from 'routes/push-notification'
import CollectionList from 'routes/collections/collection-list'
import GenreList from 'routes/genres/genre-list'
import NewsList from 'routes/news/news-list'
import FaqList from 'routes/faq/faq-list'
import SettingsPage from 'routes/settings/settings'
import VideoTabSeasons from 'routes/videos/video-tab-seasons'
import VideoTabEpisodes from 'routes/videos/video-tab-episodes'
import UserLayout from 'routes/users/user-layout'
import CommentList from 'routes/comments/comment-list'
import PaymentList from 'routes/payments/payment-list'
import UserDetails from 'routes/users/user-details'
import UserDetailsTabProfile from 'routes/users/user-details-tab-profile'
import UserDetailsTabPayments from 'routes/users/user-details-tab-payments'
import UserDetailsTabPurchases from 'routes/users/user-details-tab-purchases'
import UserDetailsTabSubscriptions from 'routes/users/user-details-tab-subscriptions'
import UserDetailsTabDevices from 'routes/users/user-details-tab-devices'
import UserDetailsTabViewHistory from 'routes/users/user-details-tab-view-history'
import UserDetailsTabAccounts from 'routes/users/user-details-tab-accounts'
import VideoTabTags from 'routes/videos/video-tab-tags'
import VideoTabTrailers from 'routes/videos/video-tab-trailers'
import LoginPage from 'routes/login'

const router = createBrowserRouter(createRoutesFromElements(
  <Route>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/" element={<Layout />}>
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="news" element={<NewsList />} />
      <Route path="news/create" element={<NewsCreatePage />} />
      <Route path="news/:id" element={<NewsEditPage />} />
      <Route path="push" element={<PushNotificationPage />} />
      <Route path="videos" element={<VideosList />} />
      <Route path="videos/:id" element={<VideoView />}>
        <Route path="edit" element={<VideoTabEdit />} />
        <Route path="images" element={<VideoTabImages />} />
        <Route path="overview" element={<VideoTabOverview />} />
        <Route path="reviews" element={<VideoTabReviews />} />
        <Route path="persons" element={<VideoTabPersons />} />
        <Route path="analytics" element={<VideoTabAnalytics />} />
        <Route path="files" element={<VideoTabFiles />} />
        <Route path="trailers" element={<VideoTabTrailers />} />
        <Route path="seasons" element={<VideoTabSeasons />} />
        <Route path="seasons/:seasonId" element={<VideoTabEpisodes />} />
        <Route path="prices" element={<VideoTabPrices />} />
        <Route path="genres" element={<VideoTabGenres />} />
        <Route path="collections" element={<VideoTabCollections />} />
        <Route path="tags" element={<VideoTabTags />} />
        <Route path="others" element={<VideoTabOthers />} />
      </Route>
      <Route path="users" element={<UserLayout />}>
        <Route path="details/:id" element={<UserDetails />}>
          <Route index element={<UserDetailsTabProfile />} />
          <Route path="payments" element={<UserDetailsTabPayments />} />
          <Route path="purchases" element={<UserDetailsTabPurchases />} />
          <Route path="subscriptions" element={<UserDetailsTabSubscriptions />} />
          <Route path="devices" element={<UserDetailsTabDevices />} />
          <Route path="view-history" element={<UserDetailsTabViewHistory />} />
          <Route path="accounts" element={<UserDetailsTabAccounts />} />
        </Route>
      </Route>
      <Route path="comments" element={<CommentList />} />
      <Route path="payments" element={<PaymentList />} />
      <Route path="collections" element={<CollectionList />} />
      <Route path="genres" element={<GenreList />} />
      <Route path="persons" element={<PersonList />} />
      <Route path="faq" element={<FaqList />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </Route>
))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export default function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer />
    </QueryClientProvider>
  )
}

import { useQuery } from '@tanstack/react-query'
import Pagination from 'components/pagination'
import Table from 'components/table'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export default function UserDetailsTabDevices () {
  const [currentPage, setCurrentPage] = useState(1)
  const { id } = useParams()
  const { data, isLoading } = useQuery({
    queryKey: ['users', String(id), 'sessions', String(currentPage)],
    queryFn: () => request({ url: `/users/${id}/sessions`, params: { page: currentPage } }).then(res => res.data)
  })

  const items = data?.items || []

  return (
    <div className="px-4">
      <Table
        isLoading={isLoading}
        items={items}
        labels={['UserAgent', 'Страна', 'IP', 'DeviceID', 'DeviceType', 'Создан']}
        renderItem={item => ([item.ua, item.country, item.ip, item.deviceId, item.deviceType, item.createdAt])}
        pagination={(
          <Pagination
            currentPage={currentPage}
            totalPage={data?.totalPage}
            totalCount={data?.totalCount}
            onChange={setCurrentPage}
          />
        )}
      />
    </div>
  )
}

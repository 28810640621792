import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom'

function AddCollectionForm ({ collections }) {
  const { id: videoId } = useParams()
  const queryClient = useQueryClient()

  const { mutateAsync } = useMutation(data => axios.put(`${process.env.REACT_APP_API_URL}/videos/${videoId}/collections`, data, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  }))

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    mutateAsync(formData)
      .then(() => queryClient.refetchQueries(['videoCollections']))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="shadow rounded-md bg-white p-4">
        <div className="text-lg">Добавить в коллекцию</div>
        <div className="flex gap-4 items-center">
          <div>
            <select
              name="collectionId"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
              {collections.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select>
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-300"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

function CollectionList ({ collections, onDelete }) {
  return (
    <div className="mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              ID
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Название
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {collections.map((item, idx) => (
              <tr key={item.collectionId || idx}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                  {item.collectionId}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.collection.name}</td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <a href="#" onClick={() => onDelete(item)} className="text-indigo-600 hover:text-indigo-900">
                    Удалить
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default function VideoTabCollections () {
  const { id } = useParams()
  const queryClient = useQueryClient()

  const { data: collections = [] } = useQuery(['collections'], {
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/collections`).then(res => res.data)
  })

  const { data: videoCollections = [] } = useQuery(['videoCollections'], {
    queryFn: () => axios.get(`${process.env.REACT_APP_API_URL}/videos/${id}/collections`).then(res => res.data)
  })

  const { mutateAsync: deleteAsync } = useMutation(item => axios.delete(`${process.env.REACT_APP_API_URL}/videos/${item.videoId}/collections/${item.collectionId}`))
  const handleDelete = (item) => {
    if (!window.confirm(`Удалить: ${item.collection.name}?`)) {
      return
    }

    deleteAsync(item)
      .then(() => queryClient.refetchQueries(['videoCollections']))
  }

  return (
    <section>
      <AddCollectionForm
        collections={collections}
      />
      {videoCollections.length === 0 && <div className="mt-4">Нет коллекций</div>}
      {videoCollections.length > 0 &&
        <CollectionList
          collections={videoCollections}
          onDelete={handleDelete}
        />}
    </section>
  )
}

import { PencilIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from 'helpers/api-service'
import { useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'

function SeasonUpdateForm ({ season }) {
  return (
    <form className="flex flex-col mt-4">
      <input type="text" name="title" defaultValue={season.title} placeholder="title" />
    </form>
  )
}

function DisplayItem ({ season }) {
  const [isShowEdit, setIsShowEdit] = useState(false)

  return (
    <div className="border border-blue-500 p-2">
      <div className="flex justify-between items-center">
        <Link to={String(season.id)}>{season.title} | сезон: {season.number} | эпизоды: {season._count.episodes}</Link>
        <PencilIcon className="w-4 h-4 cursor-pointer" onClick={() => setIsShowEdit(!isShowEdit)} />
      </div>
      {isShowEdit && <SeasonUpdateForm season={season} />}
    </div>
  )
}

function DisplayItems ({ items }) {
  return (
    <div className="flex flex-col gap-3">
      {items.map((item, idx) => <DisplayItem key={item.id || idx} season={item} />)}
    </div>
  )
}

function FormAddSeason ({ videoId, onAdded = f => f }) {
  const { isLoading, mutateAsync } = useMutation(data => request({ method: 'POST', url: '/seasons', data }))

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    formData.set('videoId', videoId)

    mutateAsync(formData)
      .then(() => {
        toast.success('Сезон добавлен')
        form.reset()
        onAdded()
      })
      .catch(err => toast.error(err.message))
  }

  return (
    <div className="mb-4 border border-blue-500 p-2">
      <div>Добавление</div>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <input type="text" name="title" placeholder="title" />
        <input type="number" name="number" placeholder="number" />
        <button type="submit" className="bg-blue-500 disabled:bg-gray-500" disabled={isLoading}>Добавить</button>
      </form>
    </div>
  )
}

export default function VideoTabSeasons () {
  const { video } = useOutletContext()

  const { data, isLoading, refetch } = useQuery(['videos', String(video.id), 'seasons'], {
    queryFn: () => request({ url: `/videos/${video.id}/seasons` }).then(res => res.data)
  })

  const items = data?.items || []

  return (
    <div>
      <FormAddSeason
        videoId={video.id}
        onAdded={refetch}
      />

      {isLoading && <div>Loading...</div>}
      {!isLoading && items.length === 0 && <div>Нет сезонов</div>}
      {!isLoading && items.length > 0 && <DisplayItems items={items} />}
    </div>
  )
}
